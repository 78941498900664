
import { Component, Watch } from 'vue-property-decorator';
import VueBase from '@/components/VueBase.vue';
import { ISettingsUpdate } from '@/interfaces';
import { readSiteCode, readSites } from '@/store/main/getters';
import { dispatchUpdateSettings } from '@/store/main/actions';

@Component
export default class Settings extends VueBase {
  public valid = true;
  public primaryVoice: string = '';
  public secondVoice: string = '';
  public thirdVoice: string = '';
  public boNumber: string | null = null;
  public addr1: string | null = null;
  public voices = [
    {text: 'English (US)', value: 'us-female'},
    {text: 'English (UK)', value: 'uk-female'},
    {text: 'Spanish', value: 'spanish-female'},
    {text: 'French', value: 'french-female'},
  ];
  public phoneError: string = '';

  public reset() {
    this.initialize();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const voice = [this.primaryVoice];
      if (this.secondVoice) {
        voice.push(this.secondVoice);
      }
      if (this.thirdVoice) {
        voice.push(this.thirdVoice);
      }
      const updatedSettings: ISettingsUpdate = {
        voice: voice.join('|'), bo_number: this.boNumber, addr1: this.addr1,
      };
      await dispatchUpdateSettings(this.$store, updatedSettings);
    }
  }

  public updatePhone(num, phone) {
    if (!phone.regionCode) {
      this.phoneError = 'Select a country.';
    } else if (phone.isValid) {
      this.phoneError = '';
    } else {
      this.phoneError = 'This phone number is invalid.';
    }
  }

  get siteCode() {
    return readSiteCode(this.$store);
  }

  get sites() {
    return readSites(this.$store);
  }

  get site() {
    const site = this.sites.find((s) => s.code === this.siteCode);
    return site;
  }

  get hasLine() {
    return this.site !== undefined && this.site.line !== null;
  }

  public mounted() {
    this.initialize();
  }

  private initialize() {
    const site = this.site;
    if (site) {
      if (site.line && site.line.voice) {
        const voices = site.line.voice.split('|');
        if (voices.length > 0) {
          this.primaryVoice = voices[0].trim();
        }
        if (voices.length > 1) {
          this.secondVoice = voices[1].trim();
        }
        if (voices.length > 2) {
          this.thirdVoice = voices[2].trim();
        }
      } else {
        this.primaryVoice = '';
        this.secondVoice = '';
        this.thirdVoice = '';
      }
      this.boNumber = site.bo_number;
      this.addr1 = site.addr1;
    }
  }

  @Watch('siteCode')
  private async watchSiteCode(val: string) {
    this.initialize();
  }

  @Watch('sites')
  private async watchSites() {
    this.initialize();
  }
}
